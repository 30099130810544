<template>
    <el-row>
        <el-col :span="24"   :class="flag ? 'titbox' : 'titbox titboxno'">
            <el-col class="title" :span="8">{{msg}}</el-col>
            <el-col class="text-right" :span="16">
                <slot name="content" :val="msgs" @click="jumpMyOrder"></slot>
            </el-col>
        </el-col>
    </el-row>
</template>

<style lang="less" scoped>

 .titbox{
        padding: 20px;
        border-bottom:none;
        // &.titboxno{
        //      border:none;
        // }
        .title{
            border-left: 2px solid #FF3333;
            font-size: 16px;
            line-height: 22px;
            padding-left: 13px;
            color:#333333;
            font-weight: 600;
        }
    }
.el-col/deep/ {
    padding: 0px;
}
  
</style>

<script>
export default {
    name:"TitleHeader",
    data(){
        return{
           msgs:"查看全部" ,
           flag:true
        }
    },
    props:{
        msg:{
            type:String
        },
        noBorder:{
            type:Boolean
        }
    },
    created(){
        if(this.noBorder){
             this.flag=false
        }
     
  },
    methods:{
       jumpMyOrder(){
           this.$router.push({name:"MyOrder"})
       } 
    }
  }
 
</script>
